$foo: 'Foo', Helvetica, Arial, sans-serif;
$pfsquare: 'PF Square Sans Pro', Helvetica, Arial, sans-serif;
$ptsans: 'PT Sans', Helvetica, Arial, sans-serif;

$yellow: #ffe300;;
$orange: #ec6020;

$text-color: #000;

@import "_mixins.scss";
@import "_fonts.scss";
//@import "_font-awesome.scss";

@import "_bootstrap.scss";

@import "_common.scss";

@import "modules/_header.scss";
@import "modules/_main-screen.scss";
@import "modules/_product.scss";
@import "modules/_production.scss";
@import "modules/_info.scss";
@import "modules/_contacts.scss";

@import "modules/_footer.scss";
@import "modules/_popup.scss";

//@import "modules/_modals.scss";
