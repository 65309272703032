.production {
  height: 100%;
  position: relative;
  &__left {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-position: center right;
    background-size: cover;
  }
  &__right {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-position: center left;
    background-size: cover;
  }
  &__content {
    padding-top: 155px;
    padding-bottom: 60px;
    position: relative;
  }
  &__wrap {
    display: table;
  }
  &__images {
    display: table-cell;
    vertical-align: top;
    width: 61%;
    font-size: 0;
  }
  &__item {
    display: inline-block;
    vertical-align: top;
    max-width: 300px;
    width: 48%;
    margin-bottom: 26px;
    &:nth-child(2n+1) {
      margin-right: 4%;
    }
  }
  &__text {
    display: table-cell;
    vertical-align: top;
    width: 39%;
    padding-left: 45px;
    p {
      font-weight: 700;
      font-size: 26px;
      line-height: 1.2;
      &:not(:last-child) {
        margin-bottom: 70px;
      }
    }
  }
  &-2 {
    padding-top: 155px;
    padding-bottom: 60px;
    position: relative;
    &__wrap {
      display: table;
    }
    &__text {
      display: table-cell;
      vertical-align: top;
      width: 41%;
      padding-right: 45px;
      p {
        font-weight: 700;
        font-size: 21px;
        line-height: 1.5;
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
    &__images {
      display: table-cell;
      vertical-align: top;
      width: 59%;
      font-size: 0;
    }
    &__item {
      display: inline-block;
      vertical-align: top;
      width: 48%;
      position: relative;
      &:first-child {
        margin-right: 4%;
      }
      img {
        margin: auto;
      }
      span {
        font-size: 20px;
        color: #fff;
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
