.popup {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(../images/bg-popup.png);
  background-position: center center;
  text-align: center;
  padding-bottom: 60px;
  opacity: 0;
  visibility: hidden;
  transition: .5s;
  &:before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &__close {
    font-weight: 700;
    font-size: 60px;
    color: #c88104;
    line-height: 1;
    transform: rotate(45deg);
    position: absolute;
    right: -90px;
    top: -25px;
    transition: .2s;
    &:hover {
      color: #ffea6e;
    }
  }
  &__content {
    max-width: 780px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    min-height: 400px;
  }
  &__callback {
    width: 455px;
    margin-left: -227px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    transition: .5s;
    &.active {
      position: static;
      opacity: 1;
      visibility: visible;
      margin: 0 auto;
    }
  }
  &__price{
    width: 455px;
    margin-left: -227px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    transition: .5s;
    &.active {
      position: static;
      opacity: 1;
      visibility: visible;
      margin: 0 auto;
    }
  }
  &__thanks {
    width: 455px;
    margin-left: -227px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    transition: 0s;
    transition-delay: 0s;
    &.active {
      position: static;
      opacity: 1;
      visibility: visible;
      transition: .5s;
      transition-delay: .5s;
      margin: 0 auto;
    }
  }
  &__confid {
    max-width: 780px;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transition: 0s;
    transition-delay: 0s;
    &.active {
      position: static;
      opacity: 1;
      visibility: visible;
      transition: .5s;
      transition-delay: .5s;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 20px;
  }
  &__footer {
    text-align: left;
  }
}

.thanks {
  &__title {
    font-weight: 700;
    font-size: 38px;
    color: #0d0d0d;
    line-height: 1.1;
    margin-bottom: 40px;
  }
  &__desc {
    font-weight: 700;
    font-size: 26px;
    color: #0d0d0d;
    line-height: 1.2;
    margin-bottom: 70px;
  }
  &__btn {
    margin-bottom: 35px;
    a {
      font-weight: 700;
      font-size: 18px;
      color: #6d3b30;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.05em;
      line-height: 55px;
      border: 0;
      background-color: transparent;
      display: inline-block;
      padding: 0 25px;
      min-width: 400px;
      height: 55px;
      transition: .3s;
      position: relative;
      span {
        position: relative;
        z-index: 10;
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid #c9942e;
        opacity: .5;
        position: absolute;
        top: 0;
        right: -2px;
        transition: .3s;
      }
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid #f6ea73;
        position: absolute;
        bottom: 3px;
        left: 5px;
        transition: .3s;
      }
      &:hover {
        color: #000;
        &:before {
          background-color: rgba(#db951b, .5);
        }
        &:after {
          background-color: rgba(#fff324, .5);
        }
      }
      &:active {
        top: 1px;
      }
    }
  }
  &__remark {
    font-size: 12px;
  }
}

.confid {
  p {
    font-size: 21px;
    margin-bottom: 20px;
  }
}