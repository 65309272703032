.product {
  height: 100%;
  @extend .clearfix;
  position: relative;
  &__left {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-position: center right;
    background-size: cover;
  }
  &__right {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-position: center left;
    background-size: cover;
  }
  &__content {
    padding: 140px 0 60px 50px;
  }
  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 46px;
  }
  &__desc {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 55px;
  }
  &__btn {
    font-size: 17px;
    color: #6d3c30;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 55px;
    display: inline-block;
    padding: 0 25px;
    min-width: 390px;
    height: 55px;
    transition: .3s;
    position: relative;
    span {
      position: relative;
      z-index: 10;
    }
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid #db951b;
      position: absolute;
      top: 0;
      right: -2px;
      transition: .3s;
    }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid $yellow;
      position: absolute;
      bottom: 3px;
      left: 5px;
      transition: .3s;
    }
    &:hover {
      &:before {
        background-color: rgba(#db951b, .5);
      }
      &:after {
        background-color: rgba(#fff324, .5);
      }
    }
    &:active {
      top: 1px;
    }
  }
  &-2 {
    padding: 145px 0 60px 1px;
    &__row {
      margin-bottom: 35px;
    }
    &__label {
      font-size: 18px;
      color: #767676;
      margin-bottom: 5px;
    }
    &__value {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.2;
    }
    &__btn {
      font-weight: 700;
      font-size: 18px;
      color: #6d3c30;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.05em;
      line-height: 55px;
      display: inline-block;
      padding: 0 25px;
      margin-top: 18px;
      min-width: 310px;
      height: 55px;
      transition: .3s;
      position: relative;
      span {
        position: relative;
        z-index: 10;
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid #e1c287;
        position: absolute;
        top: 0;
        right: -2px;
        transition: .3s;
      }
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid $yellow;
        position: absolute;
        bottom: 3px;
        left: 5px;
        transition: .3s;
      }
      &:hover {
        color: #000;
        &:before {
          background-color: rgba(#db951b, .5);
        }
        &:after {
          background-color: rgba(#fff324, .5);
        }
      }
      &:active {
        top: 1px;
      }
    }
  }
}
