/* This stylesheet generated by Transfonter (https://transfonter.org) on May 31, 2017 11:58 PM */

@font-face {
  font-family: 'Foo';
  src: url('../fonts/Foo-Regular.eot');
  src: url('../fonts/Foo-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Foo-Regular.woff2') format('woff2'),
       url('../fonts/Foo-Regular.woff') format('woff'),
       url('../fonts/Foo-Regular.ttf') format('truetype'),
       url('../fonts/Foo-Regular.svg#Foo-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Bold.eot');
  src: url('../fonts/PFSquareSansPro-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PFSquareSansPro-Bold.woff2') format('woff2'),
       url('../fonts/PFSquareSansPro-Bold.woff') format('woff'),
       url('../fonts/PFSquareSansPro-Bold.ttf') format('truetype'),
       url('../fonts/PFSquareSansPro-Bold.svg#PFSquareSansPro-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Regular.eot');
  src: url('../fonts/PFSquareSansPro-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PFSquareSansPro-Regular.woff2') format('woff2'),
       url('../fonts/PFSquareSansPro-Regular.woff') format('woff'),
       url('../fonts/PFSquareSansPro-Regular.ttf') format('truetype'),
       url('../fonts/PFSquareSansPro-Regular.svg#PFSquareSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-ExtraBlack.eot');
  src: url('../fonts/PFSquareSansPro-ExtraBlack.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PFSquareSansPro-ExtraBlack.woff2') format('woff2'),
       url('../fonts/PFSquareSansPro-ExtraBlack.woff') format('woff'),
       url('../fonts/PFSquareSansPro-ExtraBlack.ttf') format('truetype'),
       url('../fonts/PFSquareSansPro-ExtraBlack.svg#PFSquareSansPro-ExtraBlack') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Italic.eot');
  src: url('../fonts/PFSquareSansPro-Italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PFSquareSansPro-Italic.woff2') format('woff2'),
       url('../fonts/PFSquareSansPro-Italic.woff') format('woff'),
       url('../fonts/PFSquareSansPro-Italic.ttf') format('truetype'),
       url('../fonts/PFSquareSansPro-Italic.svg#PFSquareSansPro-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-BoldItalic.eot');
  src: url('../fonts/PFSquareSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PFSquareSansPro-BoldItalic.woff2') format('woff2'),
       url('../fonts/PFSquareSansPro-BoldItalic.woff') format('woff'),
       url('../fonts/PFSquareSansPro-BoldItalic.ttf') format('truetype'),
       url('../fonts/PFSquareSansPro-BoldItalic.svg#PFSquareSansPro-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-MediumItalic.eot');
  src: url('../fonts/PFSquareSansPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PFSquareSansPro-MediumItalic.woff2') format('woff2'),
       url('../fonts/PFSquareSansPro-MediumItalic.woff') format('woff'),
       url('../fonts/PFSquareSansPro-MediumItalic.ttf') format('truetype'),
       url('../fonts/PFSquareSansPro-MediumItalic.svg#PFSquareSansPro-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Medium.eot');
  src: url('../fonts/PFSquareSansPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFSquareSansPro-Medium.woff2') format('woff2'),
  url('../fonts/PFSquareSansPro-Medium.woff') format('woff'),
  url('../fonts/PFSquareSansPro-Medium.ttf') format('truetype'),
  url('../fonts/PFSquareSansPro-Medium.svg#PFSquareSansPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-Regular.eot');
  src: url('../fonts/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PTSans-Regular.woff2') format('woff2'),
       url('../fonts/PTSans-Regular.woff') format('woff'),
       url('../fonts/PTSans-Regular.ttf') format('truetype'),
       url('../fonts/PTSans-Regular.svg#PTSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-Italic.eot');
  src: url('../fonts/PTSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PTSans-Italic.woff2') format('woff2'),
       url('../fonts/PTSans-Italic.woff') format('woff'),
       url('../fonts/PTSans-Italic.ttf') format('truetype'),
       url('../fonts/PTSans-Italic.svg#PTSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-Bold.eot');
  src: url('../fonts/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PTSans-Bold.woff2') format('woff2'),
       url('../fonts/PTSans-Bold.woff') format('woff'),
       url('../fonts/PTSans-Bold.ttf') format('truetype'),
       url('../fonts/PTSans-Bold.svg#PTSans-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/PTSans-BoldItalic.eot');
  src: url('../fonts/PTSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PTSans-BoldItalic.woff2') format('woff2'),
       url('../fonts/PTSans-BoldItalic.woff') format('woff'),
       url('../fonts/PTSans-BoldItalic.ttf') format('truetype'),
       url('../fonts/PTSans-BoldItalic.svg#PTSans-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}
