.contacts {
  height: 100%;
  position: relative;
  padding-bottom: 60px;
  &__left {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-position: center right;
    background-size: cover;
  }
  &__right {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-position: center left;
    background-size: cover;
  }
  &__content {
    padding-top: 135px;
    max-width: 450px;
  }
  &__form {
    width: 100%;
    padding-top: 135px;
    text-align: center;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
    &.active {
      opacity: 1;
      visibility: visible;
      position: static;
      margin: 0;
    }
  }
  &__thanks {
    padding-top: 200px;
    text-align: center;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: 0s;
    transition-delay: 0s;
    &.active {
      opacity: 1;
      visibility: visible;
      position: static;
      transition: .5s;
      transition-delay: .5s;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 30px;
  }
  &__vk {
    display: inline-block;
    vertical-align: middle;
    width: 37px;
    height: 21px;
    margin-left: 75px;
    &:before {
      content: '';
      display: block;
      width: 37px;
      height: 21px;
      background-image: url(../images/vk-ico.png);
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      transition: .3s;
    }
    &:hover:before {
      transform: scale(1.3)
    }
  }
  &__item {
    padding-left: 70px;
    padding-right: 15px;
    margin-bottom: 35px;
    position: relative;
    &:after {
      content: '';
      width: 50px;
      height: 50px;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -25px;
    }
    &.-marker:after {
      background-image: url(../images/marker-ico.png);
    }
    &.-tel:after {
      background-image: url(../images/tel-ico.png);
    }
    &.-mail:after {
      background-image: url(../images/mail-ico.png);
    }
  }
  &__label {
    font-size: 18px;
    color: #767676;
  }
  &__value {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
  }
  &__tel {
    font-size: 22px;
    color: #000;
    display: inline-block;
    padding: 13px 0;
  }
  &__mail {
    font-size: 22px;
    color: #000;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #000;
      position: absolute;
      left: 0;
      bottom: 2px;
    }
  }
}

.form {
  padding-top: 10px;
  &__field {
    max-width: 400px;
    margin: 0 auto 15px;
    input {
      width: 100%;
      height: 60px;
      border: 1px solid #fff;
      border-radius: 10px;
      background-color: #fff;
      padding: 0 30px;
      font-size: 18px;
      @include placeholder(#c7c7c7);
      &.valid {
        box-shadow: 0 0 3px 1px rgba(green, .6);
        color: green;
      }
      &.error {
        box-shadow: 0 0 3px 1px rgba(red, .6);
        @include placeholder(red);
      }
    }
  }
  &__btn {
    margin-bottom: 30px;
    margin-top: 35px;
    button {
      font-weight: 700;
      font-size: 18px;
      color: #6d3b30;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.05em;
      line-height: 55px;
      border: 0;
      background-color: transparent;
      display: inline-block;
      padding: 0 25px;
      min-width: 400px;
      height: 55px;
      transition: .3s;
      position: relative;
      span {
        position: relative;
        z-index: 10;
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid #c9942e;
        opacity: .5;
        position: absolute;
        top: 0;
        right: -2px;
        transition: .3s;
      }
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid #f6ea73;
        position: absolute;
        bottom: 3px;
        left: 5px;
        transition: .3s;
      }
      &:hover {
        color: #000;
        &:before {
          background-color: rgba(#db951b, .5);
        }
        &:after {
          background-color: rgba(#fff324, .5);
        }
      }
      &:active {
        top: 1px;
      }
    }
  }
  &__remark {
    font-size: 12px;
    padding-left: 15px;
  }
}

