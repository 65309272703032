.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  min-width: 960px;
  &__wrap {
    display: table;
    width: 100%;
  }
  &__logo {
    display: table-cell;
    vertical-align: top;
    width: 26%;
    padding: 35px 0;
  }
  &__nav {
    display: table-cell;
    vertical-align: top;
    width: 50%;
    padding: 43px 0;
  }
  &__callback {
    display: table-cell;
    vertical-align: top;
    width: 24%;
    padding: 44px 0;
  }
}
.logo {
  &__title {
    display: block;
    font-family: $foo;
    font-size: 35px;
    line-height: 1;
    color: $yellow;
    text-shadow: 0 2px 0 #81560a;
    margin-bottom: 3px;
  }
  &__desc {
    display: block;
    font-size: 13px;
    color: #8a8a8a;
  }
}
.nav {
  line-height: 1;
  &__list {
    padding-left: 0;
    margin-bottom: 0;
    text-align: justify;
    font-size: 0;
    &:after {
      content: '';
      width: 100%;
      height: 0;
      display: inline-block;
    }
  }
  &__item {
    display: inline-block;
    vertical-align: top;
  }
  &__link {
    font-weight: 700;
    font-size: 15px;
    color: #292929;
    text-transform: uppercase;
  }
  &__count {

  }
  &__count_item {
    font-size: 10px;
    color: #727272;
    margin-right: 5px;
    &.active {
      color: #8c693c;
    }
  }
}
.tel {
  text-align: right;
  &__btn {
    font-size: 13px;
    color: $text-color;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 36px;
    display: inline-block;
    min-width: 185px;
    padding: 0 25px;
    height: 38px;
    transition: .3s;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid #e1c287;
      position: absolute;
      top: 0;
      right: -2px;
    }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid $yellow;
      position: absolute;
      bottom: 3px;
      left: 5px;
    }
    &:active {
      top: 1px;
    }
  }
}
