* {
  outline: 0 !important;
}

.clearfix {
  &:after, &:before {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

html, body {
  height: 100%;
}

.wrapper {
  height: 100%;
  &__section {
    height: 100%;
  }
}

body {
  font-family: $ptsans;
  font-size: 16px;
  color: $text-color;
  min-width: 960px;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

h1 {
  font-family: $pfsquare;
  font-weight: 900;
  font-size: 62px;
  line-height: 1;
  letter-spacing: 0.079em;
  color: $yellow;
  text-shadow: 0 4px 0 #c9942e;
  margin: 0;
}

h2 {
  font-family: $pfsquare;
  font-weight: normal;
  font-size: 21px;
  color: #41332d;
  margin: 0;
  line-height: 1.2;
}

h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 1;
  margin: 0;
}


p {
  margin: 0;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.wrapper__section {
  position: relative;
  &.active {
    .down {
      opacity: 1;
      visibility: visible;
      transition-delay: 1.5s;
      transform: translateY(0);
    }
  }
}

.down {
  font-family: $pfsquare;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .2em;
  color: #61514b;
  display: inline-block;
  padding-bottom: 30px;
  position: absolute;
  left: 50%;
  bottom: 45px;
  margin: -20px;
  opacity: 0;
  visibility: hidden;transform: translateY(50px);
  transition: 1s;
  &:after {
    content: '';
    width: 20px;
    height: 15px;
    background-image: url(../images/down-arrow.png);
    background-size: 100% 100%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -10px;
    animation: down-motion 2s infinite;
  }
}


@-webkit-keyframes down-motion  {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

.bg-screen {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/slide-1.jpg);
  transition: .7s ;
  &:before {
    content: '';
    width: 9%;
    height: 100%;
    background: url(../images/main-screen_img_left.png) center top no-repeat;
    background-size: 100% auto;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 1s;
    @media (min-width: 1440px) {
      width: 14%;
    }
  }
  &:after {
    content: '';
    width: 17.3%;
    height: 100%;
    background: url(../images/main-screen_img_right.png) center bottom no-repeat;
    background-size: 100% auto;
    position: fixed;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: 1s;
    @media (min-width: 1440px) {
      width: 17.3%;
    }
  }
  &.slide-1 {
    &:before {
      opacity: 1;
      visibility: visible;
    }
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
  &.slide-2 {
    background-image: url(../images/slide-2.jpg);
  }
  &.slide-3 {
    background-image: url(../images/slide-3.jpg);
  }
  &.slide-4 {
    background-image: url(../images/slide-4.jpg);
  }
  &.slide-5 {
    background-image: url(../images/slide-5.jpg);
  }
  &.slide-6 {
    background-image: url(../images/slide-6.jpg);
  }
  &.slide-7 {
    background-image: url(../images/slide-7.jpg);
  }
}

.btn {
  font-size: 13px;
  color: $text-color;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 36px;
  display: inline-block;
  width: 185px;
  height: 38px;
  transition: .3s;
  position: relative;
  span {
    position: relative;
    z-index: 10;
  }
  &:before {
    content: '';
    width: 185px;
    height: 38px;
    border: 1px solid #e1c287;
    position: absolute;
    top: 0;
    right: -2px;
    transition: .3s;
  }
  &:after {
    content: '';
    width: 185px;
    height: 38px;
    border: 1px solid $yellow;
    position: absolute;
    bottom: 3px;
    left: 5px;
    transition: .3s;
  }
  &:hover {
    &:before {
      background-color: rgba(#fff324, .6);
    }
    &:after {
      background-color: rgba(#fff324, .6);
    }
  }
  &:active {
    top: 1px;
  }
}

.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}


