@mixin placeholder($color: black) {
  &::-webkit-input-placeholder { color: $color; opacity: 1 }
  &:-moz-placeholder           { color: $color; opacity: 1 }
  &::-moz-placeholder          { color: $color; opacity: 1 }
  &:-ms-input-placeholder      { color: $color; opacity: 1 }
}

@mixin mobile {
  @media (max-width: $screen-xs-max) {
    @content
  }
}

@mixin tablet {
  @media (min-width: $screen-sm-min) {
    @content
  }
}

@mixin laptop {
  @media (min-width: $screen-md-min) {
    @content
  }
}

@mixin desctop {
  @media (min-width: $screen-lg-min) {
    @content
  }
}