.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  padding: 20px 0;
  font-size: 15px;
  background-color: #e8e8e8;
  &__conf {
    font-size: 15px;
    color: #7c88c2;
    border-bottom: 1px dotted #7c88c2;
  }
  &__copyright {
    text-align: center;
    white-space: nowrap;
  }
  &__38ru {
    text-align: right;
    a {
      color: #7a85b7;
      border-bottom: 1px solid #7c88c2;
    }
  }
}
