.info {
  height: 100%;
  .container {
    height: 100%;
  }
  &__wrap {
    height: 100%;
    @extend .clearfix;
  }
  &__item {
    height: 100%;
    width: 33.3333%;
    float: left;
    padding: 155px 25px 60px;
    transition: .4s;
    &:hover {
      background-color: #fbf291;
      .info__icon {
        background-color: #d5b46d;
      }
    }
  }
  &__icon {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin: 0 auto 20px;
    background-color: rgba($yellow, .6);
    position: relative;
    transition: .4s;
    img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }
  &__title {
    text-align: center;
    padding-bottom: 27px;
    margin-bottom: 27px;
    position: relative;
    &:after {
      content: '';
      width: 34px;
      height: 1px;
      background-color: #d5c031;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -17px;
    }
  }
  &__text {
    text-align: center;
    p {
      font-size: 18px;
      color: #464646;
      line-height: 1.2;
    }
  }
}