.main-screen {
  padding-top: 135px;
  padding-bottom: 60px;
  height: 100%;
  background-position: center center;
  background-size: cover;
  &__title {
    margin-bottom: 45px;
  }
  &__item {
    padding-left: 77px;
    position: relative;
    margin-bottom: 26px;
    &:after {
      content: '';
      width: 36px;
      height: 44px;
      background-image: url(../images/bee.png);
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -22px;
    }

    &:nth-child(2):after {
      transform: rotate(90deg);
    }
    &:nth-child(3):after {
      transform: rotate(180deg);
    }
    &:nth-child(4):after {
      transform: rotate(270deg);
    }
  }
  &__vk {
    display: inline-block;
    width: 37px;
    height: 21px;
    transform: translateX(-500px);
    transition: 1s;
    transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
    &:before {
      content: '';
      display: inline-block;
      width: 37px;
      height: 21px;
      background-image: url(../images/vk-ico.png);
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      transition: .3s;
    }
    &:hover:before {
      transform: scale(1.3)
    }
  }
}


.wrapper__section.active {
  .main-screen__vk {
    opacity: 1;
    visibility: visible;
    transition-delay: .5s;
    transform: translateX(0);
  }
}